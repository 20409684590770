/* тут все стили которые были написаны до применения sass */
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #FFFFFF;
  color: #111;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  width: 100%;
  line-height: 1.625;
}

H1, H2 {
  color: #27292E;
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
  margin: 0;
}

H2 {
  font-size: 40px;
}

a {
  color: #27292E;
  text-decoration: none;
}

.clr {
  clear: both;
}

.logo a {
  line-height: 58px;
  font-size: 20px;
}

.logo a span.logo-text {
  border: 2px solid #fff;
  padding-left: 12px;
  display: inline-block;
  letter-spacing: 1px;
}

.logo a span.logo {
  border: 2px solid #303030;
  display: inline-block;
  width: 58px;
  line-height: 58px;
  color: #303030;
  text-align: center;
  font-size: 30px;
}

.intro-content h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 13px;
  text-align: center;
}

.intro-content > p {
  text-align: center;
  color: #676767;
}

.our_gualiti .col-xs-4 {
  margin-bottom: 3em;
}

a.button {
  background-color: #8db552;
  border: 1px solid #8db552;
  color: #FFFFFF;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  border-radius: 2px;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  padding: 12px 52px;
  text-decoration: none;
}

a.button:hover {
  opacity: 0.9;
  text-decoration: none;
  color: #FFFFFF;
}

ul.menu {
  list-style: none;
}

#header {
  background: none repeat scroll 0 0 #fff;
  border: none !important;
  padding: 32px 0;

  @media (max-width: 480px) {
    padding: 0;
  }

}

#header .counter {

  @media (max-width: 480px) {
    display: none;
  }
}

#main {
  border: none !important;
  padding: 32px 0 0;
  width: 100%;

  @media (max-width: 480px) {
    padding: 0;
  }
}

#main h1 {
  font-size: 52px;
  font-weight: 700;
  line-height: 62px;
  margin: 0 0 26px;

  @media (max-width: 480px) {
    margin: 0;
  }
}

#main h1 span {
  color: #1abc9c;
}

#main p {
  margin-bottom: 3px;
}

#main p.lead {
  color: #939898;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 52px;
}

.right_block {
  float: right;
  width: 30%;
}

#main_bottom {
  margin-top: 72px;
}

#main_bottom h2 {
  text-align: center;
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 38px;
}

#main_bottom p img {
  margin-left: 27px;
  border: 1px solid #e5e5e5;
  padding: 15px;
}

.col-md-4 {
  width: 300px;
  float: left;
  padding: 0 15px 45px;
  text-align: center;
}

.icon-wrapper {
  background: none repeat scroll 0 0 #8db552;
  border-radius: 2px;
  display: block;
  height: 78px;
  line-height: 78px;
  margin: 0 auto;
  width: 78px;
}

.icon-wrapper img {
  vertical-align: middle;
  margin-left: 15px;
}

.intro-content {
  margin-top: 26px;
}

.intro-content h3 {
  margin-bottom: 13px;
}

#content {
  padding: 5px 0 52px;
}

.lead_text1 p {
  margin: 13px 0;
  color: #f97352;
  display: block;
  font-size: 22px;
  line-height: 26px;
}

.offers-block {
  margin-left: -14px;
  overflow: hidden;
}

#cr_block_new {
  border: 1px solid #e6e6e5;
  border-radius: 2px;
  margin-left: 14px;
  margin-top: 20px;
  overflow: hidden;
  padding: 16px 0;
}

#cr_block_new:hover {
  border: 1px solid #303030;
  cursor: pointer;
}

#cr_block_new #cr_cont {
  float: left;
  width: 140px;

  @media (min-width: 374px) and (max-width: 400px) {
    width: auto;
    text-align: center;
  }
}

#cr_img {
  padding: 0px 29px 0px;

  @media (max-width: 400px) {
    padding: 0;
  }
}

#cr_block_new #cr_p_new {
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  padding: 0 14px;
  width: 178px;
  text-align: left;
}

.cr_pzag {
  color: #888;
  display: inline-block;
  font-weight: normal;
  padding-bottom: 5px;
  width: 40px;

  @media (max-width: 374px) {
    width: auto;
  }
}

#cr_p5 {
  border-radius: 8px;
  border: 2px solid #969696;
  color: #303030;
  float: left;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  width: 150px;
  margin: 9px 15px 0;

  @media (min-width: 374px) and (max-width: 400px) {
    margin: 0;
    margin-top: 15px;
    float: none;
    display: inline-block;
  }

  @media (max-width: 374px) {
    float: none;
    display: inline-block;
    width: auto;
    padding: 0 5px;
    margin: 0;
    margin-top: 15px;
  }
}

#cr_block_new:hover #cr_p5 {
  background-color: #303030;
  border-color: #303030;
  color: #fff;
}

ul.menu_credits {
  margin: 72px auto 0;
  border-radius: 2px;
}

ul.menu_credits li {
  list-style: none;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  width: 25%;
}

ul.menu_credits li a {
  border: 1px solid #E6E6E5;
  border-radius: 0;
  padding: 12px 0;
  transition: none 0s ease 0s;
  display: block;
  box-sizing: border-box;
  border-left-color: #fff;
}

ul.menu_credits li.item-103 a {
  border-left-color: #E6E6E5;
  border-radius: 2px 0 0 2px;
}

ul.menu_credits li.item-106 a {
  border-radius: 0 2px 2px 0;
}

ul.menu_credits li.active a, ul.menu_credits li.active a:hover {
  background: none repeat scroll 0 0 #2F3238;
  border-color: #27292E;
  color: #FFFFFF !important;
}

ul.menu_credits li a:hover {
  background: none repeat scroll 0 0 #E6E6E5;
  border-color: #E6E6E5;
}

.logo {
  float: left;
}

.counter {
  float: right;
  color: #969696;
  line-height: 52px;
  text-align: right;
}

.counter span {
  border: 1px solid #969696;
  padding: 5px;
  font-size: 28px;
  margin-top: 2px;
  border-radius: 2px;
  color: #333;
}

.counter span.text {
  font-size: 16px;
  border: none;
  line-height: 52px;
  margin: 0;
  color: #969696;
}

#info_text {
  padding: 22px 0 72px;
}

#info_text p {
  margin-bottom: 26px;
}

.info_text_r {
  float: right;
}

.info_text_r .module {
  padding: 13px 26px;
  font-size: 14px;
  border: 1px solid #E6E6E5;
}

.info_text_r .module span {
  font-weight: bold;
  width: 240px;
  display: inline-block;
}

#reviews, #info_t {
  background: #f6f6f6;
  width: 100%;
  padding-bottom: 78px;
  padding-top: 78px;
  color: #939898;
}

#info_t {
  color: #303030;
  font-size: 14px;
}

#reviews h2, #info_t h2, #offers h2 {
  text-align: center;
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 38px;
}

#info_t ul {
  border: 1px solid #fff;
}

#info_t li {
  float: left;
  width: 50%;
  border: 1px solid #fff;
  text-align: center;
  padding: 10px 10px 0;
  font-size: 14px;
}

#info_t li h3 {
  text-transform: uppercase;
  line-height: 1.7;
}

#info_t li p {
  font-size: 13px;
}

#reviews .custom {
  border-bottom: 1px solid #3D4045;
  padding-bottom: 10px;
}

.reviews_all {
  margin-bottom: 38px;
  overflow: hidden;
  width: 100%;
}

.reviews_img img {
  height: auto;
  width: 200px;
}

div.right_arrow {
}

.reviews_img {
  float: left;
  width: 200px;
}

.reviews_text {
  border: 2px solid #1abc9c;
  border-radius: 2px;
  height: auto;
  padding: 26px 39px;
  text-align: left;
  float: right;
  width: 672px;
  color: #303030 !important;
}

.info_text_r a {
  border-bottom: 1px solid #8db552;
}

.reviews_text span {
  display: block;
  margin-top: 26px;
  color: #767676;
}

.mainmenu {
  margin: 1em 0;
}

.mainmenu .menu {
  min-height: 2.5em;
  line-height: 2.5em;
  border: 1px solid #ccc;
}

.mainmenu .menu li {
  float: left;
  display: block;
  position: relative;
  list-style: none;
  width: 156px;
  border-right: 1px solid #ccc;
}

.mainmenu .menu li:last-child {
  border-right: none;
}

.mainmenu .menu a {
  text-decoration: none;
  padding: 0 1em;
  display: block;
}

.mainmenu .menu ul {
  display: none;
  position: absolute;
  top: 2.5em;
  left: -1px;
  min-width: 160px;
  background: #fff;
  border: none;
}

.mainmenu .menu ul ul {
  left: 100%;
  top: -1px;
}

.mainmenu .menu li.parent li {
  float: none;
  border: 1px solid #ccc;
  border-top: none;
}

.mainmenu .menu li:hover {
  background: #ccc;
}

.mainmenu .menu a:hover {
  color: #fff;
}

.mainmenu .menu li:hover ul ul,
.mainmenu .menu li:hover ul li:hover ul ul {
  display: none;
}

.mainmenu .menu li:hover ul,
.mainmenu .menu li:hover ul li:hover ul,
.mainmenu .menu li:hover ul li:hover ul li:hover ul {
  display: block;
}

#footer {
  padding: 78px 0 130px;
}

#footer a {
  color: #111;
  text-decoration: none;
  border-bottom: 1px solid #1abc9c;
}

#footer a:hover, .info_text_r a:hover {
  color: #969696;
  border-bottom: 1px solid #fff;
}

#footer li {
  line-height: 26px;
}

#footer li a {
  border: none;
}

#footer li a:hover {
  color: #969696;
}

.copyrights {
  line-height: 32px;
  margin: 28px 0 0;
}

.foot_copy {
  float: left;
  display: block;
  width: 80%;

  @media (max-width: 768px) {
    width: 74%;
  }
}

.foot_cont {
  float: right;
  display: block;
  width: 20%;
  font-size: 12px;
  line-height: 18px;

  @media (max-width: 768px) {
    width: 26%;
  }
}

.foot_cont a {
  border: 2px solid #1abc9c !important;
  padding: 8px 10px;
  border-radius: 2px;
  font-size: 14px;
  margin-top: 10px;
  display: block;
  text-align: center;
}

.foot_cont a:hover {
  background-color: #1abc9c;
  color: #fff !important;
}

.footer_r {
  float: right
}

span.jicons-text {
  display: inline-block;
}

.pane-slider {
  margin: 40px 0;
}

h3#display-form {
  font-size: 24px;
}

.contact-form dt {
  display: block;
  width: 150px;
  float: left;
}

.contact-form dd {
  margin-bottom: 20px !important;
}

div#foxcontainer_c127 select {
  width: 270px;
  border: 1px solid #aaaaaa;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
  padding: 4px 5px;
}

table.offers_info {
  border: 1px solid #eee;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-top: 40px;
}

table.offers_info th {
  border-bottom: 2px solid #eee;
  padding: 10px;
  text-align: left;
}

table.offers_info td {
  border-top: 1px solid #eee;
  padding: 10px;
}

#form-credit {
  margin-top: -260px;
  margin-bottom: 50px;
}

#form-credit ul li {
  float: left;
  width: 48%;
  margin: 0 1%;
  margin-bottom: 25px;
}

#form-credit ul li input, #form-credit ul li select {
  padding: 7px 10px;
  border: 2px solid #303030;
  border-radius: 4px;
  width: 100%;
}

#form-credit input[type="submit"] {
  background-color: #1abc9c;
  border: 2px solid #1abc9c;
  border-radius: 4px;
  padding: 7px 10px;
  color: #fff;
  margin-left: 1%;
}

#form-credit input[type="submit"]:hover, #form-credit input#to-mainpage:hover {
  background-color: #303030;
  border: 2px solid #303030;
}

#form-credit input#to-mainpage {
  background-color: #969696;
  border: 2px solid #969696;
}

.banner {
  margin-top: 40px;
}

.banner a {
  display: inline-block;
}

#programs_block {
  margin-left: -14px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
}

/* @media */

@media (min-width: 1000px) {
  .container {
    width: 980px;
    margin: 0 auto;
    max-width: none !important;
  }
  #info_t li {
    min-height: 178px;
  }
}

@media (max-width: 768px) {
  #cr_block_new
  #cr_cont {
    float: none;
    margin: 0 auto;
    text-align: left;
  }
  #cr_block_new
  #cr_p_new {
    padding: 0;
  }
  #cr_p5 {
    margin-left: 0;
  }
  #info_t li {
    width: 100%;
    min-height: auto;
    float: none;
  }
}

@media (max-width: 480px) {
  #main h1 {
    font-size: 40px;
    line-height: 1.2;
    margin-top: 14px;
    position: relative;
    top:12px
  }

  .foot_copy {
    float: none;
    width: auto;

    p {
      line-height: 1.4;
    }
  }
  .foot_cont {
    float: none;
    width: auto;
    margin-top: 30px;
  }
  #footer {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media  (max-width: 400px) {
  #cr_block_new
  #cr_cont {
    text-align: center;
    padding: 4px;
  }
}

#header {

}

#footer {

}