.bem_foot {
  &__info {
    font-size:12px;
    line-height:18px;
    padding-right:130px;

    @media (max-width: 480px) {
      padding-right: 0;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
}