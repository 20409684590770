.bem_offer {
  display: inline-block;
  width: 20%;
  text-align: center;
  vertical-align: top;

  @media (max-width: 992px) {
    width: 33%;
  }

  @media (max-width: 767px) {
    width: 50%;
  }

  @media (max-width: 319px) {
    width: 100%;
  }
}