$parallelogram-bg: #FF4242;
$parallelogram-text: #fff;
.parallelogram {
  background: $parallelogram-bg;
  color: $parallelogram-text;
  font-weight: 500;
  font-family: 'Open Sans';
  font-size: 11px;
  padding: 0 6px;
  box-sizing: border-box;
  text-transform: uppercase;
  min-width: 130px;

  span {
    line-height: 16px;
  }

  &,
  &__left-angle,
  &__right-angle {
    display: inline-block;
    vertical-align: bottom;
    height: 16px;
  }

  &__left-angle {
    border-left: solid transparent 7px;
    border-bottom: solid $parallelogram-bg 16px;
    margin-left: -7px;

    /*@media (max-width: 400px) {
      display: none;
    }*/
  }

  &__right-angle {
    border-right: solid transparent 7px;
    border-top: solid $parallelogram-bg 16px;
    margin-right: -7px;


    /*@media (max-width: 400px) {
      display: none;
    }*/
  }

  &__wrapper {
    line-height: 0.9;
    min-height: 18px;
    white-space: nowrap;
    margin-bottom: 15px;
  }
}