.content{font-size:1em;line-height:1.5em;color:#333;}
.content h1{font-size:1.6em;margin:0 0 1em;}
.content h2{font-size:1.5em;margin:1.6em 0 1em;}
.content h3{font-size:1.2em;margin:1.5em 0 0.75em;}
.content h4{font-size:1.1em;margin:1.2em 0 0.5em;}
.content h5{font-size:1em;margin:1em 0 0.5em;}
.content h6{font-size:1em;margin:1em 0 0.5em;}
.content hr{display:block;background:#aaa;width:100%;height:1px;border:none;}
.content ul, .content ol{margin-left:2em;margin-bottom:1em;}
.content ol ol{margin-left:2.5em;}
.content ul > li{list-style:url('../images/list.gif');list-style-position:outside;}
.content ol > li{list-style-image:none;list-style-type:decimal;}
.content dt{font-weight:bold;}
.content dl dt{margin-bottom:0.25em;font-weight:bold;}
.content dl dd{padding-left:4em;margin-bottom:0.25em;}
.content table{width:100%;border-top:solid 2px #222;}
.content table caption{color:#999;font-style:italic;}
.content table th{text-align:right;}
.content table thead th{text-align:center;border-bottom:solid 2px #222;}
.content table th, .content table td{line-height:1.5em;padding:1.5em 0.75em 0.25em;background:none;}
.content table thead th, .content table thead td{padding-top:0.75em}
.content table tfoot th, .content table tfoot td{padding-bottom:0.75em;font-style:italic;}
.content table tbody th, .content table tbody td, .content table tfoot th, .content table tfoot td{border-top:solid 1px #000}
.content table tr:nth-child(even) td{}
.content table tr:hover{background:none!important;}
.content table tbody tr:nth-child(odd) th, .content table tbody tr:nth-child(odd) td{background:rgba(0, 0, 0, 0.05);}
.content blockquote p:first-child{margin:1em 0;}
.content hr, .content p, .content ul, .content ol, .content dl, .content pre, .content blockquote, .content address, .content form, .content table{margin-bottom:0.5em;}
.content p+p{margin-bottom:1em;}
.content p{text-indent:0;}
.content caption{padding:0.5em 0;}
.content blockquote{padding:0 1em;margin:1.8em 0;}
.content a:link{text-decoration:underline;color:#36c;}
.content a:visited{text-decoration:underline;color:#36c;}
.content a:hover{text-decoration:none;color:#36c;}
.content a:active, .content a:focus{text-decoration:underline;color:#36c;}
.content a{text-decoration:underline;color:#36c;}
.content a:hover{text-decoration:none;}
.content a[target="_blank"], .content a.external{background:url("../images/external-link.gif")no-repeat 100% 50%;padding-right:13px;}
.content pre{margin:0.5em 0;padding:0.5em 0 0.5em 1em;border:1px dotted #aab4be;border-left:1.8em solid #b4b4b4;background:#fafafa;font-size:90%;color:#2E8B57;white-space:pre-wrap;}
.content code{word-spacing:-2px;color:#D64514;}
.content img, .content img.alignImageLeft, .content img.alignImageRight{border:1px solid #ccc;}
.content a:hover img{border-color:#aaa;outline:1px solid #ff0;}
.content img[align="left"], .content img.alignImageLeft{float:left;margin:0 1em 0.5em 0;}
.content img[align="right"], .content img.alignImageRight{float:right;margin:0 0 0.5em 1em;}
.content blockquote{border-top:1px solid #ccc;border-bottom:1px solid #ccc;color:#666;font-style:italic;}
blockquote *:before{content:"\201C";}
blockquote *:after{content:"\201D";}
q:before{content:"\00AB";}
q:after{content:"\00BB";}
.content legend, .content fieldset, .content input,.content textarea,.content select, .content a.button, .content button{border-radius:3px;}
.content fieldset{margin:1.8em 0;}
.content legend{margin:0.5em 0;white-space:normal;font-size:16px;}
.content legend+*{margin-top:1em;}
.content input[type="checkbox"], .content input[type="radio"], .content option, .content select{margin:0 0.45em;}
.content form.form-mode-1 label{display:block;}
.content form .row{margin-bottom:0.75em;}
.content form .row-input-colonm input[type="radio"], .content form .row-input-colonm input[type="checkbox"]{float:left;}
.content form .row-input-colonm label{display:block;margin-bottom:0.5em;}
.content input[type="text"], .content input[type="password"], .content select, .content textarea{padding:4px;min-width:200px;border:1px solid #ccc;background-color:#fff;box-shadow:none;color:#777;border-radius:2px;}
.content input[type="text"]:focus, .content input[type="password"]:focus, .content select:focus, .content textarea:focus{outline:none;color:#000;border-color:#b9b9b9;border-top-color:#919191;background-color:#fff;box-shadow:inset 0 0 5px rgba(0, 0, 0, 0.12);}
.content button, .content input[type="button"], .content input[type="reset"], .content input[type="submit"], .content .button{background-color:#8db552;border:1px solid #8db552;border-radius:2px;color:#ffffff;cursor:pointer;display:inline-block;font-size:18px;line-height:26px;margin:0;padding:12px 52px;text-align:center;text-decoration:none;vertical-align:middle}
.content button:hover, .content input[type="button"]:hover, .content input[type="reset"]:hover, .content input[type="submit"]:hover,
.content .button:hover, .content .button:hover{opacity:0.9;}
.content button:active, .content input[type="button"]:active, .content input[type="reset"]:active,
.content input[type="submit"]:active, .content .button:active, .content .button:active{border-color:#cfcfcf;border-top-color:#a2a2a2;border-bottom-color:#cfcfcf;box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.1);}
.content input:invalid,.content textarea:invalid,.content input.error,.content textarea.error,.content legend.error,
.content select.error{background-color:#f0dddd!important;border:1px solid #ffb4b4!important;color:#000!important;}
.content input:invalid:focus,.content textarea:invalid:focus,.content input.error:focus,.content textarea.error:focus,
.content legend.error:focus,.content select.error:focus{background-color:#fff!important;}